/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    voicemail: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 8.5A3.5 3.5 0 015.95 11h4.1a3.5 3.5 0 112.45 1h-9A3.5 3.5 0 117 8.5m-6 0a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0m14 0a2.5 2.5 0 10-5 0 2.5 2.5 0 005 0"/>',
    },
});
